<template>
  <div class="home">
    <home-hero-section />
    <home-image-section align="right" bg-src="v2/hero-home-mobile">
      <template #title>
        <span>Switch to Truly </span>
        <span>Unlimited Mobile.</span>
      </template>
      <template #text>
        <span>Get <strong>3 months HALF PRICE*</strong> </span>
        <span>on your selected plan.</span>
      </template>
      <template #cta>
        <ui-button tag="app-link" to="/mobile">Find Your Mobile Plan</ui-button>
      </template>
      <template #icon>
        <Smartphone :size="100" :stroke-width="2" />
      </template>
    </home-image-section>

    <home-image-section align="left" bg-src="v2/hero-home-broadband">
      <template #title>
        <span>Switch to Naturally Fast Broadband.</span>
      </template>
      <template #text>
        <span>Get up to <strong>6 months HALF PRICE*</strong> </span>
        <span>on your selected plan.</span>
      </template>
      <template #cta>
        <ui-button tag="app-link" to="/plans">Find Your Broadband Plan</ui-button>
      </template>
      <template #icon>
        <Wifi :size="100" :stroke-width="2" />
      </template>
    </home-image-section>

    <home-image-section align="right" bg-src="v2/hero-home-bundles">
      <template #title>
        <span>Save Big* with </span>
        <span>bundle deals.</span>
      </template>
      <template #text>
        <span>Sign up for Broadband and Mobile.</span>
      </template>
      <template #cta>
        <ui-button tag="app-link" to="/bundles">Select Your Bundle</ui-button>
      </template>
      <template #icon>
        <Boxes :size="100" :stroke-width="2" />
      </template>
    </home-image-section>

    <home-cta-section>
      <template #title> Heard of eSIM? </template>
      <template #text>
        <p>The latest technology that helps you connect faster than a conventional SIM.</p>
      </template>
      <template #cta>
        <ui-button tag="app-link" to="/mobile" :modifier="['liliac']">Read More</ui-button>
      </template>
    </home-cta-section>
    <span class="line" />
    <company-features />
  </div>
</template>

<script lang="ts" setup>
import CompanyFeatures from '@/components/local/CompanyFeatures.vue';
import { definePageMeta, useJsonld } from '#imports';
import HomeHeroSection from '~/components/local/home/HomeHeroSection.vue';
import HomeImageSection from '~/components/local/home/HomeImageSection.vue';
import UiButton from '~/components/global/ui/UiButton.vue';
import { Smartphone, Wifi, Boxes } from 'lucide-vue-next';
import HomeCtaSection from '~/components/local/home/HomeCtaSection.vue';

definePageMeta({
  key: (route) => route.fullPath,
  pageType: 'home',
  pageTitle: 'Sustainable Fibre Broadband & Internet Provider',
  pageDescription:
    "Zeronet is New Zealand's first sustainable internet provider. We offer unlimited data Fibre internet, but with less impact on the planet.",
});

useJsonld({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Zeronet',
  url: 'https://www.zeronet.co.nz/',
  logo: 'https://www.zeronet.co.nz/',
  sameAs: ['https://www.facebook.com/zeronet.nz/', 'https://www.instagram.com/zeronetnz/'],
});
</script>

<style scoped lang="scss">
.home .line {
  display: block;
  height: 3px;
  background: var(--zeronet-liliac);
}
</style>
