<template>
  <div ref="el" class="hero">
    <div class="hero__inner container">
      <div class="hero__block">
        <div ref="title" class="hero__title">
          <h1>
            <span>Unlimited data. </span>
            <span>Unlimited happiness.</span>
          </h1>
        </div>
        <div ref="text" class="hero__text">
          <span><strong>Save Big*</strong> when you sign up for </span>
          <span><strong>Broadband and Mobile</strong>. Plus we’ll </span>
          <span>plant 5 trees on your behalf.</span>
        </div>
        <div ref="ctas" class="hero__cta">
          <ui-button tag="app-link" to="/plans#plans">Find Your Broadband Plan</ui-button>
          <ui-button tag="app-link" to="/mobile">Find Your Mobile Plan</ui-button>
        </div>
      </div>
    </div>
    <responsive-image image-url="bg/hero" alt="Internet Provider: Zeronet" class="hero__img" />
  </div>
</template>

<script lang="ts" setup>
import UiButton from '~/components/global/ui/UiButton.vue';
import { onMounted, ref } from '#imports';
import type { Nullable } from '~/types/generic';
import ResponsiveImage from '~/components/global/ui/ResponsiveImage.vue';

const el = ref<Nullable<HTMLElement>>(null);
const title = ref<Nullable<HTMLElement>>(null);
const text = ref<Nullable<HTMLElement>>(null);
const ctas = ref<Nullable<HTMLElement>>(null);

onMounted(() => {
  nextTick(() => {
    if (!title.value || !text.value || !el.value) {
      return;
    }
  });
});
</script>

<style scoped lang="scss">
.hero {
  position: relative;
  z-index: 10;
  padding: calc-clamp(320, $desktop, 60, 80) 0;
  background-color: var(--zeronet-liliac);
  @include tablet {
    min-height: 70vh;
  }
  display: flex;
  align-items: center;

  &__inner {
    z-index: 2;
    position: relative;
  }

  &__img {
    overflow: hidden;
    @include cover;

    :deep(*) {
      @include cover;
    }
  }

  &__block {
    border-radius: 2em;
    background: var(--zeronet-liliac);
    padding: 2em;
    max-width: rem(600);
  }

  &__title {
    font-family: var(--heading-font-family);
    line-height: 1.05;
    @include fluid(font-size, 30, 42, 54);
    @include tablet {
      :deep(span) {
        display: block;
      }
    }
  }

  &__text {
    margin: 1em 0;
    position: relative;
    font-size: rem(19);
    font-weight: 500;
    line-height: 1.14;
    @include tablet {
      :deep(span) {
        display: block;
      }
    }
    :deep(small) {
      font-size: 0.75em;
    }
    :deep(sup) {
      display: inline-block;
      vertical-align: inherit;
      transform: translateY(-50%);
      font-size: 0.7em;
    }
  }

  &__cta {
    margin-top: 1.5em;
    display: flex;
    gap: 1em;

    @include mobile {
      flex-direction: column;
      gap: 0.5em;
    }
  }
}
</style>
