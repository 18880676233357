<template>
  <div ref="root" class="company-features">
    <div class="company-features__items">
      <div v-for="item in items" :key="item.title" v-observe="onObserve" class="company-features__item">
        <div class="company-features__item-icon">
          <video preload="auto" :class="{ 'is-desktop': item.iconReverse }" :src="`/images/${item.icon}.mp4`" muted autoplay loop playsinline />
          <video v-if="item.iconReverse" preload="auto" class="is-touch" :src="`/images/${item.iconReverse}.mp4`" muted autoplay loop playsinline />
        </div>
        <h4 class="company-features__item-title is-title">
          <span v-for="(letter, index) in item.title" :key="`title-letter${index}`">{{ letter }}</span>
        </h4>
        <div class="company-features__item-text">
          <span v-for="(row, index) in item.text" :key="`text-row${index}`">{{ row }}</span>
        </div>
      </div>
    </div>
  </div>
  <hand-icons />
</template>

<script lang="ts" setup>
import HandIcons from '@/components/global/elements/HandIcons.vue';
import { onMounted, ref, useNuxtApp } from '#imports';

const root = ref<HTMLElement | null>(null);

const { $gsap } = useNuxtApp();

interface ItemIcon {
  icon: string;
  alt: string;
  title: string[];
  text: string[];
  iconReverse?: string;
  reverseAlt?: string;
}

const items: ItemIcon[] = [
  {
    icon: 'zeronet-forgood-hand',
    alt: 'Ethical Fibre Broadband Via Zeronet',
    title: 'For good'.split(''),
    text: ['Solar powered and committed', 'to planting trees'],
  },
  {
    icon: 'zeronet-easy-hand',
    alt: 'Easy Broadband Via Zeronet',
    iconReverse: 'zeronet-easy-hand-reverse',
    reverseAlt: 'Sustainable Internet Provider - Zeronet',
    title: 'Easy'.split(''),
    text: ['Hassle-free set-up', 'and connection'],
  },
  {
    icon: 'zeronet-friendly-hand',
    alt: 'Friendly internet provider support with Zeronet',
    title: 'Friendly'.split(''),
    text: ['Supportive helpdesk and no', 'confusing tech-speak'],
  },
];

let tl: gsap.core.Timeline | null = null;
const onObserve = () => {
  if (tl) {
    tl.play();
  }
};

onMounted(() => {
  if (!root.value) {
    return;
  }
  tl = $gsap.timeline({ paused: true });

  const items = root.value.querySelectorAll('.company-features__item');
  const titles = root.value.querySelectorAll('.company-features__item-title span');
  const texts = root.value.querySelectorAll('.company-features__item-text span');
  const icons = root.value.querySelectorAll('svg');

  $gsap.set(items, { opacity: 0, y: 20 });
  $gsap.set(icons, { opacity: 0, y: 20 });
  $gsap.set(titles, { opacity: 0, y: 10 });
  $gsap.set(texts, { opacity: 0, y: 10 });

  tl.to(items, { opacity: 1, duration: 1, y: 0, ease: 'power4.out', stagger: 0.25 }, 0);
  tl.to(icons, { opacity: 1, duration: 1, y: 0, ease: 'power4.out', stagger: 0.25 }, 0.5);
  tl.to(titles, { opacity: 1, duration: 1, y: 0, ease: 'power4.out', stagger: 0.075 }, 0.5);
  tl.to(texts, { opacity: 1, duration: 1, y: 0, ease: 'power4.out', stagger: 0.2 }, 0.75);
});
</script>

<style lang="scss" scoped>
@use '@/assets/mixins/__index' as mix;

.company-features {
  position: relative;
  color: var(--primary);
  background-color: var(--primary);

  @include desktop {
    padding: rem(80) calc(var(--container-spacing));
  }

  &__items {
    @include grid(false, false, (repeat(3, 1fr), rem(50)));

    @include desktop {
      place-items: center;
      align-items: stretch;
    }
  }

  &__item {
    text-align: center;
    background-color: var(--dark-pink);
    padding: rem(20) rem(40) rem(50);

    @include touch {
      &:nth-child(even) {
        background-color: var(--primary);
        color: var(--secondary);
      }
    }

    @include desktop {
      padding: rem(20) rem(10) rem(40);
      border-radius: rem(25);
      width: min(100%, rem(260));
    }

    &-icon {
      width: 100%;
      @include mix.unselectable(true);
      @include touch {
        max-width: rem(220);
        margin-left: auto;
        margin-right: auto;
      }

      video {
        width: 100%;
        mix-blend-mode: darken;

        &.is-touch {
          mix-blend-mode: lighten;
        }
      }
    }

    &-title {
      font-size: rem(40);
      margin: 0 0 rem(20);

      span {
        display: inline-flex;
        min-width: 0.3em;
      }

      @include mobile {
        font-size: rem(32);
      }
    }

    &-text {
      font-weight: 500;
      font-size: rem(20);

      @include mobile {
        font-size: rem(18);
      }

      span {
        display: block;
      }

      @include desktop {
        font-size: rem(15);
      }
    }
  }
}
</style>
